$yellow: #FFE921;
$turquoise: #01B1AF;
$black: #000;
$white: #fff;
$grey: #fafafa;


// vars
$type-title-font: 'Montserrat', sans-serif;
$type-body-font: 'Spinnaker', sans-serif;
$type-body-size: 16px;

// rhythm
// best to have increments for more control adjusting
$type-leading: 1.5;

// scale
$type-scale: 1.25;



// type edits?
.heading,
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: $black;
}
