
.stack {
    position: relative;
    padding: 6rem 0 4.5rem;
}

.stack-overlay a {
    color: inherit;
}

.stack--large {
    padding: 9rem 0 6rem;

    .stack-overlay {
        padding: 9rem 0 6rem;
    }
}

.stack-overlay {
    background: rgba($black, 0.4);
    padding: 3rem 0 1.5rem;
}

.stack--img {
    padding: 0!important;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: #fff;
    text-shadow: 1px 1px 1px #000;

    & .heading,
    & .btn {
        text-shadow: none;
        &:hover {
            color: $turquoise;
        }
    }
}

.stack--black {
    background: lighten($black, 10%);
    color: #fff;
}

.stack--grey {
    background: $grey;
}

.stack--pattern {
    padding: 0;

    .stack-fill {
        padding: 6rem 0 4.5rem;
        background-color: rgba($yellow, 0.5);
        background-color: rgba($turquoise, 0.5);
    }
}

.stack--pattern h2,
.stack--pattern p,
.stack--pattern .btn {
    color: darken($turquoise, 15%);
}

.stack--pattern .btn::after,
.stack--pattern .field {
    border-color: darken($turquoise, 10%);
    color: #fff;
}

.stack--pattern .field {
    font-size: 1.375rem;
}
