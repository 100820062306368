* {
    box-sizing: border-box;
    transition: all ease .3s;
}

.row {
    @extend .cf;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
}

a {
    color: $turquoise;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}



.centered {
    margin: 0 auto;
}

.heading,
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: $turquoise;
}

.heading {
    background: $yellow;
    display: inline-block;
    padding: 3px;
    transform: rotate(-3deg);

    &:hover {
        transform: rotate(2deg);
    }
}

// h1, .alpha { font-size: 2rem; }
// h2, .beta { font-size: 1.5rem; }
// h3, .gamma { font-size: 1.375rem; }
// h4, .delta   { font-size: 1.125rem; }
// h5, .epsilon { font-size: 1rem; }
// h6, .zeta { font-size: 1rem; }

h1, .alpha   { @include scale(5); }
h2, .beta    { @include scale(4); }
h3, .gamma   { @include scale(3); }
h4, .delta   { @include scale(2); }
h5, .epsilon { @include scale(1); }
h6, .zeta    { @include scale(1); }


.mega {
    font-size: 3rem;
}


.logo {
    // position: absolute;
    // top: 0;
    // display: block;
    // width: 4rem;
    // background: $white;
}

.logo img {
    display: inline-block;
    vertical-align: middle;
    max-height: 4rem;
    width: auto;
}

.header {
    // background: $turquoise;
    // background: $yellow;
    // color: #fff;
    padding: .75rem;
}

.header .column {
    padding-bottom: 0;
}

.menu-main {
    padding: 1.5rem 0;
    text-align: right;
}

.menu-main a {
    display: inline-block;
    margin-left: 1rem;
    // color: #fff;
}

.center {
    text-align: center;
}

.social-links a {
    font-size: 3rem;
    display: inline-block;
    margin: .375rem .75rem;
    color: #fff;
}

.social-links a:hover {
    transform: translate(0, -10px);

    .fa-facebook { color: #3b5998; }
    .fa-twitter { color: #1da1f2; }
    .fa-instagram { color: #f56040; }
}




/**
 * Forms
 */

button,
input[type="text"],
input[type="email"],
input[type="submit"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    font: inherit;
    background: transparent;

    &:focus {
        outline: 0;
    }
}

.field {
    display: block;
    width: 100%;
    padding: rhythm(2);
    border: 2px solid $turquoise;
}

.contact-thankyou {
    display: block;
    position: absolute;
    z-index: 2;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: 0 auto;
    font-size: 1.6rem;
    text-align: center;
    background: rgba($grey, 0.25);
    color: $turquoise;
}

.contact-message .fa {
    margin-top: 20%;
    font-size: 3rem;
}

.contact-message p {
    position: relative;
    z-index: 2;
    margin: 20% auto 0;
    width: 80%;
    max-width: 540px;
    font-size: 1.5rem;
    background: #fff;
    padding: 1rem;

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 5px;
        left: 5px;
        border: 3px solid $turquoise;
        transform: rotate(-1deg);
    }
}

input.btn,
button.btn,
.btn {
    font-family: $type-title-font;
    position: relative;
    display: inline-block;
    color: $turquoise;
    border: 2px solid $yellow;
    padding: rhythm(2);
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    cursor: pointer;

    &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        border: 2px solid $turquoise;
        top: 0;
        left: 0;
        transform: rotate(-3deg);
        transition: all ease .3s;
    }

    &:hover {
        text-decoration: none;
        background: $yellow;

        &::after {
            transform: rotate(2deg);
        }
    }
}

.btn--large {
    font-size: 1.5em;
}


.img {
    position: relative;
    width: 90%;
    height: auto;

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 5px;
        left: 5px;
        border: 5px solid $turquoise;
        transform: rotate(2deg);
    }
}


/**
 * Responsive Grid
 */

.m-show {
    display: none;
}

.menu-mobile-trigger {
    float: right;
    font-size: 1.5rem;
    margin-top: 1rem;
}

.menu-mobile-container {
    display: none;
    padding-top: 3rem;
    background: rgba(#fff, .9);
    @include position(fixed, 0);
    font-size: 1.5rem;
}

.menu-mobile a {
    display: block;
    text-align: center;
    padding: .75rem;
}

@media(max-width: 800px) {

    html,
    body {
        font-size: 14px;
    }

    .m-hide {
        display: none;
    }

    .m-show {
        display: block;
    }

    .column.m-one-half {
        margin: 0;
        width: 50%;
    }

    .column.m-one-whole {
        margin: 0;
        width: 100%;
    }

}

@media(max-width: 480px) {
    html,
    body {
        font-size: 12px;
    }

    .column.s-one-whole {
        margin: 0;
        width: 100%;
    }
}
