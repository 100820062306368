.faq {
    padding: rhythm(4);
    background: $turquoise;
    color: $white;
    transition: all ease .3s;
}

.faq-question {
    color: $yellow;
    line-height: 1.3;
}
